.count-panel {
	display: flex;
	width: 100%;
	height: 50vh;
}

.count-panel > div {
	width: 50%;
	height: 100%;
}
.team-count-panel,
.participants-count-panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url("../../../src/assets/blob.svg");
	background-repeat: no-repeat;
	background-size: 370px 370px;
	background-position: center;
}

.team-count {
	font-family: "Open Sans", sans-serif;
	font-weight: 800;
	font-size: 4rem;
}

.team-text {
	font-family: "Open Sans", sans-serif;
	font-weight: 4 00;
	font-size: 2rem;
}

.grid-panel {
	width: 95%;
	height: 90vh;
	margin: 2rem;
}

.grid-panel-topbar {
	margin-bottom: 1rem;
}

.grid-header {
	font-size: 1.2rem;
	font-weight: 600;
}

.MuiDataGrid-columnHeadersInner {
	color: #38a3a5;
}
.MuiDataGrid-columnHeaderTitle {
	font-weight: bold !important;
	font-size: 1rem;
}

.team-name-click {
	cursor: pointer;
	font-weight: 600;
}

.participant-details-modal-grid {
	height: 45vh;
}

@media (max-width: 450px) {
	.count-panel {
		flex-direction: column;
	}
	.count-panel > div {
		width: 100%;
	}
}
