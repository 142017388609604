@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Comfortaa", cursive;
}

.maxx-height {
	height: 200vh;
}

::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #808080;
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background: hsl(34, 100%, 40%);
}

.main-app {
	padding-top: 78px;
	min-height: calc(100vh - 50px);
}
