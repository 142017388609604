@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
.login-outer-container {
    align-items: center;
    justify-content: center;
    /* padding: 5rem; */
}
.login-inner-container {
    width: 25rem;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: saturate(100%) blur(10px);
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    margin: auto;
    margin-top: 15vh;
}
.login-form {
    padding: 20px;
    color: white;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.login-text {
    font-family: "Comfortaa", cursive;
    text-align: center;
    margin-top: 2rem;
}
.login-big {
    font-size: 2rem;
}
.login-form-internal {
    margin-top: 1.5rem;
}
.login-button {
    margin-top: 1.3rem;
    margin-bottom: 2rem;
}
@media(max-width: 500px)
{
    .login-inner-container {
        width: 90vw;
        margin-top: 25vh;
    }
    
}