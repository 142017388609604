@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.topbar {
    width: 100%;
    height: 78px;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  
  .topbarWrapper {
    background-color: white;
    height: 100%;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    opacity: 10;
    z-index: 999;
  }

  .topLeft-transparent {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 10;
    z-index: 999;
    color: #fff;
  }

  .topLeft-transparent > img {
    margin : 1rem;
  }

  .topLeft-transparent .top-text {
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    font-family: "Roboto", arial, sans-serif;
  }
  
  .top-logo {
    margin: 1rem;
  }
  .top-text {
    font-weight: bold;
    font-size: 30px;
    color: #000;
    cursor: pointer;
  }
  .top-text .link {
    text-decoration: none !important;
    color: inherit;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }
  
  .topRight {
    display: flex;
    align-items: center;
  }
  .topLeft {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
  }
  
  .topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
  
  .topAvatar {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .top-logout {
    cursor: pointer;
    margin-left: 1rem;
  }
  .top-details {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #000;
  }
  .top-details > div {
    margin-right: 1rem;
  }

  .top-user {
    color: #000;
  }
  .link{
    display: flex;
    text-decoration: none !important;
    align-items: center;
    color: inherit;
    font-size: 30px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }

  .link > img {
    margin: 1rem;
    align-items: center;
  }

  .top-user .top-name {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }
  .top-user .top-domain {
    font-size: 0.9rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }
  @media screen and (max-width: 600px) {
    .top-text {
      display: none;
    }
    .top-user {
      display: none;
    }
  }